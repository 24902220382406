import React from 'react';
import Carousel from './components/Carousel';
import Quotes from './components/Quotes';
import { connect } from '@app/#config/store';

const Home = props => {
  return (
    <div>
      <Carousel />
      <Quotes />
    </div>
  );
};

export default connect('LANGUAGE')(Home);
