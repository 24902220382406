import React from 'react';
import { Carousel } from 'antd';
import styles from './style.module.less';

const CarouselComponent = () => {
  return (
    <Carousel autoplay speed={1000} autoplaySpeed={5000} effect="fade">
      <div>
        <img src={require('@app/assets/webp/home_bg1.webp')} alt="bg1" />
        <div className={styles.text}>
          <p style={{ fontSize: '7.92812vmin', textAlign: 'left', lineHeight: '1.5' }}>
            <span>全球领先的</span>
            <br />
            <span>数字货币合约交易平台</span>
          </p>
          <p style={{ color: '#ebce5b', fontSize: '4.75687vmin' }}>安全&nbsp;&nbsp;&nbsp;&nbsp;简单&nbsp;&nbsp;&nbsp;&nbsp;快速</p>
        </div>
      </div>
      <div>
        <img src={require('@app/assets/webp/home_bg2.webp')} alt="bg2" />
        <div className={styles.text}>
          <p style={{ fontSize: '7.92812vh', textAlign: 'left', lineHeight: '1.5' }}>
            注 册 即 挖 矿
            <br />
            <span style={{ fontSize: '4.75687vmin' }}>
              <span style={{ color: '#ebce5b' }}>1024万</span>枚BYD先到先得
            </span>
          </p>
        </div>
      </div>
      <div>
        <img src={require('@app/assets/webp/home_bg3.webp')} alt="bg3" />
        <div className={styles.text}>
          <p style={{ fontSize: '7.92812vmin', textAlign: 'left', lineHeight: '1.5' }}>
            全球招募100位KOL达人
            <br />
            高达60%返佣
          </p>
        </div>
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
